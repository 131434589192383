import React, { Component } from 'react'
import './AboutUsPage.sass'
import { Row, Col } from 'react-bootstrap'
import Bg from '../../bg/homepage.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import PageHeader from '../../Component/PageHeader/PageHeader'
import SectionSeparator from '../../Component/SectionSeparator/SectionSeparator'
import Footer from '../../Component/Footer/Footer'
import TeamSection from '../../Component/AboutUs/TeamSection/TeamSection'
import Axios from 'axios'
import MainButton from '../../Component/MainButton/MainButton';
import { apiUrl } from "../../utils/constant"

export default class AboutUsPage extends Component {
    state = {
        header: {
            title: "About Kota",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
            buttonText: null
        },
        history: {
            title: null,
            description: null,
        },
        kota: {
            title: null,
            description: null,
        },
        footPrints: [],
        endPageText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Fusce ornare consequat tortor elementum dapibus. "
    }
    render() {
        var footPrints = this.state.footPrints.map(data => {
            return (
                <div className={"box box-" + data.id} key={data.id}>
                    <p className="fp-number">{this.abbreviateNumber(data.number)}</p>
                    <p className="fp-desc">{data.name}</p>
                </div>
            )
        })
        return (
            <Row className="about-us-page-wrapper">
                <Col className="about-us-page">
                    <BaseNavbar />
                    <img src={Bg} id="bg" alt="" />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
                            <PageHeader 
                                title={this.state.header.title}
                                desc={this.state.header.desc}
                                buttonText={this.state.header.buttonText}
                                />
                        </Col>
                    </Row>
                    <Row className="profile-section-wrapper">
                        <Col className="profile-section" lg={{ span: 10, offset: 1 }}>
                            <div className="who-kota paragraph">
                                <p className="paragraph-title">{this.state.kota.title}</p>
                                <p className="paragraph-subtitle">{this.state.kota.description}</p>
                            </div>
                            <div className="history-kota paragraph">
                                <p className="paragraph-title">{this.state.history.title}</p>
                                <p className="paragraph-subtitle">{this.state.history.description}</p>
                            </div>
                            {footPrints}
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="teams-section-wrapper">
                        <Col className="teams-section" lg={{ span: 10, offset: 1 }}>
                            <TeamSection />
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="redirect-section-wrapper">
                        <Col className="redirect-section" lg={{ span: 10, offset: 1 }}>
                            <div className="text">
                                <p className="redirect-title">{this.state.endPageText}</p>
                                <MainButton href="/contact" text="Let me know your needs" />
                            </div>
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }

    abbreviateNumber(number){
        var SI_SYMBOL = ["", "k", "m", "b", "t"];
        var tier = Math.log10(number) / 3 | 0;
        if(tier === 0) return number;
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);
        var scaled = number / scale;
        return scaled.toFixed(0) + suffix;
    }

    getHistory() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "history-of-kota",
            method: "get"
        }).then(response => {
            console.log(response.data[0].description)
            this.setState({
                history: {
                    title: "History Of Kota",
                    description: response.data[0].description
                }
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    getWhoAreKota() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "who-are-kota",
            method: "get"
        }).then(response => {
            console.log()
            this.setState({
                kota: {
                    title: "Who Are Kota",
                    description: response.data[0].description
                }
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    getFootPrints() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "our-foot-print",
            method: "get"
        }).then(response => {
            this.setState({
                footPrints: response.data
            })
            console.log(this.state.footPrints)
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }
    
    componentDidMount() {
        this.getWhoAreKota()
        this.getHistory()
        this.getFootPrints()
    }
}
