import React, { Component } from 'react'
import './FeatureSection.sass'
import FeatureCard from './FeatureCard/FeatureCard'
import axios from 'axios';
import { apiUrl } from "../../../utils/constant"

export default class FeatureSection extends Component {
    state = {
        features: []
    }

    getFeatures = this.getFeatures.bind(this)
    render() {
        var features = this.state.features.map(feature => {
            return (
                <FeatureCard data={feature} key={feature.id} />
            )
        })
        return (
            <div className="features-wrapper">
                {features}
            </div>
        )
    }

    getFeatures(id) {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        axios({
            url: apiUrl + "products/" + id + "/features",
            method: "get"
        }).then(response => {
            this.setState({
                features: response.data
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    componentDidMount() {
        this.getFeatures(this.props.productId)
    }
}
