import React, { Component } from 'react'
import './HomePage.sass'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Bg from '../../bg/homepage.jpg'
import BgTesti from '../../bg/carousel-bg.jpg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import PageHeader from '../../Component/PageHeader/PageHeader'
import ProductsFlyer from '../../Component/Home/ProductsFlyer/ProductsFlyer'
import CustomerFlyer from '../../Component/Home/CustomerFlyer/CustomerFlyer'
import Testimony from '../../Component/Home/Testimony/Testimony'
import ServicesSection from '../../Component/Home/ServicesSection/ServicesSection'
import TopicSection from '../../Component/Home/TopicSection/TopicSection'
import Footer from '../../Component/Footer/Footer'
import SectionSeparator from '../../Component/SectionSeparator/SectionSeparator'

export default class HomePage extends Component {
    state= {
        header: {
            title: "KOTA Digital Service",
            desc: "A web-based platform where you can do all kinds of business activities from purchasing, marketing, production, inventory, to accounting. Your team can run your company from anywhere anytime.",
            buttonText: "Try it for Free",
            href: "/try"
        },
        testimonySectionTitle: "What They Said"
    }
    render() {
        return (
            <Row className="home-page-wrapper">
                <Col className="home-page">
                    <BaseNavbar />
                    <img src={Bg} id="bg" alt="" />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }}>
                            <PageHeader 
                                title={this.state.header.title}
                                desc={this.state.header.desc}
                                buttonText={this.state.header.buttonText}
                                href={this.state.header.href}
                                />
                        </Col>
                    </Row>
                    <Row className="product-section-wrapper">
                        <div id="product-section-bg">
                            <div className="green-bg"></div>
                            <div className="white-bg"></div>
                        </div>
                        <Col className="product-section" lg={{ span:10, offset: 1}}>
                            <div className="product-flyer">
                                <ProductsFlyer />
                            </div>
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="services-section-wrapper">
                        <Col className="services-section" lg={{ span: 10, offset: 1 }}>
                            <ServicesSection />
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="customer-section-wrapper">
                        <Col className="customer-section" lg={{ span: 10, offset: 1 }}>
                            <CustomerFlyer />
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="testimony-section-wrapper">
                        <Col className="testimony-section" lg={{ span: 10, offset: 1 }}>
                            <p className="title">{this.state.testimonySectionTitle}</p>
                            <div className="testimony-wrapper">
                                <Testimony />
                            </div>
                        </Col>
                    </Row>
                    <Row className="topic-section-wrapper">
                        <Col className="topic-section" lg={{ span: 10, offset: 1 }}>
                            <TopicSection />
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
}
