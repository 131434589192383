import React, { Component } from 'react'
import './FaqSection.sass'
import {Accordion, Card} from 'react-bootstrap'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { apiUrl } from "../../../utils/constant"

export default class FaqSection extends Component {
    state = {
        sectionTitle: "Frequently Asked question",
        faqs: [
        ],
    }

    getFaqProduct = this.getFaqProduct.bind(this)

    render() {
        var faqs = this.state.faqs.map(faq => {
            return (
                <Accordion key={faq.id}>
                    <Card>
                        <Accordion.Toggle className="d-flex " as={Card.Header} eventKey="0">
                            <p className="question d-inline flex-grow-1">{faq.question}</p>
                            <span className="icon"><FontAwesomeIcon icon={ faPlus } /></span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p className="answer">{faq.answer}</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            )
        })
        return (
            <div className="faqs">
                <p className="faq-section-title">{this.state.sectionTitle}</p>
                <div className="faq-wrapper">
                    {faqs}
                </div>
            </div>
        )
    }

    getFaqProduct(id) {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "products/" + id + "/faq",
            method: "get"
        }).then(response => {
            this.setState({
                faqs: response.data
            })
            console.log(this.state.faqs)
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    componentDidMount() {
        this.getFaqProduct(this.props.productId)
    }
}
