import React from 'react'
import './FeatureCard.sass'

export default function FeatureCard(props) {
    const feature = props.data
    return (
        <div className="product-feature-card">
            <p className="feature-name">{feature.name}</p>
            <div className="feature-info">
                <img src={feature.img_detail} alt="feature"/>
                <div className="feature-description">
                    <p className="caption">{feature.caption}</p>
                    <p className="description">{feature.description}</p>
                </div>
            </div>
        </div>
    ) 
}
