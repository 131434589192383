import React, { Component } from 'react'
import './MoreArticle.sass'
import TopicCard from '../Home/TopicSection/TopicCard/TopicCard'
import Axios from 'axios'
import { apiUrl } from "../../utils/constant"

export default class MoreArticle extends Component {
    state = {
        moreArticle: []
    }

    getMoreArticle = this.getMoreArticle.bind(this)
    
    render() {
        var moreArticle = this.state.moreArticle.map(article => {
            return(
                <TopicCard data={article} key={article.id} />
            )
        })
        return (
            <div className="more-article">
                {moreArticle}
            </div>
        )
    }
    getMoreArticle(id = 0) {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
       
        Axios({
            url: apiUrl + "case-studies",
            method: "get"
        }).then(response => {
            var moreArticle = response.data.map(article => {
                if(id !== 0) {
                    if(article.id !== id) {
                        return article
                    } return null
                } else {
                    return article
                }
            })
            this.setState({
                moreArticle: moreArticle.slice(0,3)
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }
    componentDidMount() {
        if(this.props.renderedId) {
            this.getMoreArticle(this.props.renderedId)
        } else {
            this.getMoreArticle()
        }
    }
}
