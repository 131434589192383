import React from 'react'
import './MainCard.sass'
import MainButton from "../MainButton/MainButton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

export default function MainCard(props) {
    var data = props.data
    var image
    if(props.category === "service") {
        image = data.img_service
    } else {
        image = data.img_product
    }

    let brief = ""
    if (data.desc_brief.length >= 120) {
        brief = data.desc_brief.slice(0, 115) + "..."
    } else {
        brief = data.desc_brief
    }

    // var angleRight = <FontAwesomeIcon icon={faAngleRight} />
    const mainCard = (
        <div className="main-card">
            <img src={image} alt="main_image"/>
            <div className="main-card-body">
                <div className="main-card-body-text">
                    <p className="main-card-title">{data.name}</p>
                    <p className="main-card-desc">{brief}</p>
                </div>
                <MainButton href={props.href} text={props.buttonText} size="small" />
            </div>
        </div>
    );
    return (
        <div className="main-card-wrapper">
            { mainCard }
        </div>    
    )
}
