import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './TestimoniCarousel.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight, faCircle, faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

export default class TestimoniCarousel extends Component {
	render () {
		var slides = this.props.data.map(slide => {
			return (
				<div className="slide-wrapper" key={slide.id}>
					<div className="slide">
						<img className="testi-pic" src={slide.image} alt="user"/>
						<div className="text-wrapper">
							<div className="testimony-message">
								<p><FontAwesomeIcon className="icon" icon={faQuoteLeft} /></p>
								<p className="text">{slide.description}</p>
								<p><FontAwesomeIcon className="icon float-right" icon={faQuoteRight} /></p>
							</div>
							<div className="testimony-profile">
								<p className="name">{slide.name}</p>
								<p className="position">{slide.position}</p>
								<p className="company">{slide.company}</p>
							</div>
						</div>
					</div>
				</div>
			)
		})
		var settings = {
			customPaging: function(i) {
				return (
					<span>
						<FontAwesomeIcon icon={ faCircle } />
					</span>
				);
			},
			dots: true,
			dotsClass: "slick-dots slick-thumb",
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			nextArrow: <FontAwesomeIcon className="navigation-icon" icon={faChevronCircleRight} 
							style= "font-size: 1.5rem; color: #ffffff"/>,
			prevArrow: <FontAwesomeIcon className="navigation-icon" icon={faChevronCircleLeft} />
		};
		return (
			<div className="carousel">
				<Slider {...settings} className="slides d-flex">
					{slides}
				</Slider>
			</div>
		)
	}
}
