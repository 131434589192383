import React from 'react'
import './ServiceFeatureCard.sass'

export default function ServiceFeatureCard(props) {
    var feature = props.data
    return (
        <div className="feature-card">
            <div className="image-wrapper">
                <img src={feature.img_detail} alt="feature"/>
            </div>
            <p className="feature-name">{feature.name}</p>
            <p className="feature-desc">{feature.description}</p>
        </div>
    )
}
