import React, { Component } from 'react'
import './Footer.sass'
import Logo from '../../LogoKota/Kota_complogo_1.svg'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export default class Footer extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <Row className="footer-section-wrapper">
                        <Col className="footer-section" lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col lg={7} md={6}>
                                    <div className="kota-footer">
                                        <img src={Logo} alt="logo-kota" className="logo-kota"/>
                                        <p className="tagline">Lets Let's take your business to the next level !</p>
                                        <div className="address">
                                            <p className="text">Jalan Pinang 1 no. 54D</p>
                                            <p className="text">Rt 01 Rw 02 Pondok Labu</p>
                                            <p className="text">South Jakarta City, DKI Jakarta</p>
                                            <p className="text">12450</p>
                                            <p className="text">aufar@kotadigivice.com</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="links" lg={{ span: 4, offset: 1 }} md={6}>
                                    <div className="link-wrapper">
                                        <p className="footer-category">Main Page</p>
                                        <a href="/try" className="footer-link">Product</a>
                                        <a href="/" className="footer-link">Customer</a>
                                        <a href="/" className="footer-link">What They Say</a>
                                        <a href="/try" className="footer-link">Services</a>
                                        <a href="/homepage" className="footer-link">Interesting Stories</a>
                                    </div>
                                    <div className="link-wrapper">
                                        <p href="#" className="footer-category">About</p>
                                        <a href="/about" className="footer-link">Who Are Kota</a>
                                        <a href="/about" className="footer-link">History Of Kota</a>
                                        <a href="/about" className="footer-link">Meet Our Team</a>
                                        <a href="/about" className="footer-link">Our Foot Print</a>
                                        <a href="/contact" className="footer-link">Talk To Us</a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="copyright-section-wrapper">
                        <Col className="copyright-section" lg={{ span: 10, offset:1 }}>
                            <div className="buttons">
                                <button className="circular facebook"><FontAwesomeIcon icon={ faFacebookF } /></button>
                                <button className="circular linkedin"><FontAwesomeIcon icon={ faLinkedinIn } /></button>
                            </div>
                            <span className="copyright">Copyright KOTA 2020</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}