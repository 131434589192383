import React, { Component } from 'react'
import './TryPage.sass'
import {Helmet} from "react-helmet";
import {Row, Col} from 'react-bootstrap'
import Bg from '../../bg/homepage.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import PageHeader from '../../Component/PageHeader/PageHeader'
import MainCard from '../../Component/MainCard/MainCard'
import Axios from 'axios';
import SectionSeparator from '../../Component/SectionSeparator/SectionSeparator';
import MainButton from '../../Component/MainButton/MainButton';
import { apiUrl } from "../../utils/constant"

export default class TryPage extends Component {
    state= {
        header: {
            title: "Lorem Ipsum",
            desc: "A web-based platform where you can do all kinds of business activities from purchasing, marketing, production, inventory, to accounting. Your team can run your company from anywhere anytime.",
            buttonText: null
        },
        productSection: "Our Product",
        products: [],
        serviceSection: "Our Services",
        services: [],
        endSection: {
            title: "Want to talk with an expert?",
            subtitle: "Our team is here to answer any questions you have about KOTA"
        }
    }

    getProducts = this.getProducts.bind(this)
    getServices = this.getServices.bind(this)
    
    render() {
        var productCards = this.state.products.map(product => {
            return (
                <MainCard data={product} href="/try" buttonText="Register" category="product" key={product.id} />
            )
        })
        var serviceCards = this.state.services.map(service => {
            return (
                <MainCard data={service} href="/try" buttonText="Register" category="service" key={service.id} />
            )
        })
        return (
            <Row className="try-page-wrapper">
                <Col className="try-page">
                    <img src={Bg} id="bg" alt="" />
                    <BaseNavbar />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
                            <PageHeader 
                                title={this.state.header.title}
                                desc={this.state.header.desc}
                                buttonText={this.state.header.buttonText}
                                />
                        </Col>
                    </Row>
                    <Row className="product-section-wrapper">
                        <Col className="product-section" lg={{ span: 10, offset: 1 }}>
                            <p className="section-title">{this.state.productSection}</p>
                            <div className="product-cards">
                                {productCards}
                            </div>
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="service-section-wrapper">
                        <Col className="service-section" lg={{ span: 10, offset: 1 }}>
                            <p className="section-title">{this.state.serviceSection}</p>
                            <div className="service-cards">
                                {serviceCards}
                            </div>
                        </Col>
                    </Row>
                    <Row className="redirect-section-wrapper">
                        <Col className="redirect-section" lg={{ span: 10, offset: 1 }}>
                            <div className="redirect-text">
                                <p className="redirect-title">{this.state.endSection.title}</p>
                                <p className="redirect-subtitle">{this.state.endSection.subtitle}</p>
                            </div>
                            <MainButton href="/contact" text="Let me know your needs" />
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
    getProducts() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "products",
            method: "get"
        }).then(response => {
            this.setState({
                products: response.data
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    getServices() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "services",
            method: "get"
        }).then(response => {
            this.setState({
                services: response.data
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    componentDidMount() {
        this.getProducts()
        this.getServices()
        document.title = "Error Page"
    }
}
