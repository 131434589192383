import React, { Component } from 'react'
import './ServicesSection.sass'
import axios from 'axios'
import MainCard from '../../MainCard/MainCard'
import { apiUrl } from "../../../utils/constant"

export default class ServicesSection extends Component {
    state = {
        title: "Services",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Fusce ornare consequat tortor elementum",
        services: []
    }

    getServices = this.getServices.bind(this)

    render() {
        var services = this.state.services.map(service => {
            return (
                <MainCard data={service} key={service.id} href={"/services/" + service.id} buttonText="See more" category="service" />
            )
        })
        return (
            <div className="services">
                <p className="title">{this.state.title}</p>
                <p className="subtitle">{this.state.subtitle}</p>
                <div className="service-cards">
                    {services}
                </div>
            </div>
        )
    }

    getServices() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        // let apiUrl = ("https://api.kotadigivice.com/") 
        axios({
            url: apiUrl + "services",
            method: "get",
        })
        .then(response => {
            this.setState({
                services: response.data
            })
        })
        .catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    
    componentDidMount() {
        this.getServices()
    }
}
