import React, { Component } from 'react'
import {Row, Col} from 'react-bootstrap'
import './NotFoundPage.sass'
import Bg from '../../bg/error-bg.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import MainButton from '../../Component/MainButton/MainButton'

export default class NotFoundPage extends Component {
    state = {
        header: {
            title: null,
            desc: null,
            detail: null,
            errorMessage: null,
            buttonText: null
        }
    }
    render() {
        return (
            <Row className="not-found-page-wrapper">
                <Col className="not-found-page">
                    <img src={Bg} id="bg" alt="" />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
                            <Row>
                                <Col className="header" lg={{ span: 12 }}>
                                    <h1 className="title">{this.state.header.title}</h1>
                                    <p className="desc">{this.state.header.desc}</p>
                                    <p className="desc-detail">{this.state.header.detail}</p>
                                    <p className="error">{this.state.header.errorMessage}</p>
                                    <MainButton href="/" text={this.state.header.buttonText} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
    
    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({
            header: {
                title: params.status_code,
                desc: "ERROR",
                detail: "Internal Server",
                errorMessage: "Sorry, we had some technical problems during your last operation",
                buttonText: "Go to Homepage"
            }
        })
    }
}

