import React, { Component } from 'react'
import './ServicePage.sass'
import {Row, Col} from 'react-bootstrap'
import Bg from '../../bg/homepage.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import PageHeader from '../../Component/PageHeader/PageHeader'
import Axios from 'axios';
import FeatureSection from '../../Component/Service/FeatureSection/FeatureSection';
import MainButton from '../../Component/MainButton/MainButton';
import CustomerFlyer from '../../Component/Home/CustomerFlyer/CustomerFlyer';
import SectionSeparator from '../../Component/SectionSeparator/SectionSeparator'
import MoreArticle from '../../Component/MoreArticle/MoreArticle';
import { apiUrl } from "../../utils/constant"

export default class ServicePage extends Component {
    state= {
        header: {
            title: null,
            desc: null,
            buttonText: null,
            href: null
        },
        featureDesc: "Feature",
        bannerTitle: "",
        caseStudySection: {
            title: "Our Case Study",
            // subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor"
        }
    }

    getService = this.getService.bind(this)
    render() {
        const { match: { params } } = this.props;
        return (
            <Row className="service-page-wrapper">
                <Col className="service-page">
                    <img src={Bg} id="bg" alt="" />
                    <BaseNavbar />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
                            <PageHeader 
                                title={this.state.header.title}
                                desc={this.state.header.desc}
                                buttonText={this.state.header.buttonText}
                                href={this.state.header.href}
                                />
                        </Col>
                    </Row>
                    <Row className="feature-section-wrapper">
                        <Col className="feature-section" lg={{ span: 10, offset: 1 }}>
                            <p className="section-title">{this.state.featureDesc}</p>
                            <FeatureSection serviceId={params.id_service} />
                        </Col>
                    </Row>
                    <Row className="banner-section-wrapper">
                        <img src={Bg} alt="banner-bg"/>
                        <Col className="banner-section" lg={{ span: 10, offset: 1 }}>
                            <div className="banner-text">
                                <p className="banner-title">{this.state.bannerTitle}</p>
                                <MainButton href="/contact" text="Let us know your needs" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="customer-section-wrapper">
                        <Col className="customer-section" lg={{ span: 10, offset: 1 }}>
                            <CustomerFlyer />
                        </Col>
                    </Row>
                    <SectionSeparator />
                    <Row className="cases-section-wrapper">
                        <Col className="cases-section" lg={{ span: 10, offset: 1 }}>
                            <p className="section-title">{this.state.caseStudySection.title}</p>
                            <p className="section-subtitle">{this.state.caseStudySection.subtitle}</p>
                            <MoreArticle />
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
    getService(id) {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "services/" + id,
            method: "get"
        }).then(response => {
            this.setState({
                header: {
                    title: response.data.name, 
                    desc: response.data.description,
                    buttonText: "Let we know your needs",
                    href: "/contact"
                }
            })
        }).catch(err => {
            window.location.href = "/404/"
        })
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        this.getService(params.id_service)
    }

}
