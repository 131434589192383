import React, { Component } from 'react'
import './Plan.sass'
import {Tabs, Tab} from 'react-bootstrap'
import PlanCard from './PlanCard/PlanCard'
import Axios from 'axios';
import { apiUrl } from "../../../utils/constant"

export default class Plan extends Component {
    state = {
        monthly: [],
        annually: [],
    }
    
    getPlans = this.getPlans.bind(this)

    
    render() {
        var annualPlans = this.state.annually.map(plan => {
            return (
                <PlanCard data={plan} productId={this.props.productId}/>
            )
        })

        var monthlyPlans = this.state.monthly.map(plan => {
            return (
                <PlanCard data={plan} productId={this.props.productId}/>
            )
        })
        return (
            <Tabs className="custom-tab" defaultActiveKey="monthly">
                <Tab tabClassName="tab" eventKey="monthly" title="Monthly">
                    <div className="plan-cards">
                        {monthlyPlans}
                    </div>
                </Tab>
                <Tab tabClassName="tab" eventKey="annually" title="Annually">
                    <div className="plan-cards">
                        {annualPlans}
                    </div>
                </Tab>
            </Tabs>
        )
    }

    getPlans(productId) {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
        Axios({
            url: apiUrl + "products/" + productId + "/plans",
            method: "get"
        }).then(response => {
            var monthly = []
            var annually = []
            response.data.map(plan => {
                if (plan.periode === "monthly") {
                    monthly.push(plan)
                } else {
                    annually.push(plan)
                }
            })
            this.setState({
                monthly: monthly,
                annually: annually
            })
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }
    componentDidMount() {
        this.getPlans(this.props.productId)
    }


}
