import React, { Component } from 'react'
import "./Testimony.sass"
import TestimonyCarousel from "./TestimoniCarousel/TestimoniCarousel"
import Axios from 'axios';
import { apiUrl } from "../../../utils/constant"

export default class Testimony extends Component {
	state = {
		testimonies: []
    }
    
	getTestimony = this.getTestimony.bind(this)

    render() {
        return (
            <div className="testimony">
                <TestimonyCarousel  data={this.state.testimonies}/>
            </div>
        )
    }

	getTestimony() {
        // let apiUrl = window.location.href.includes("localhost:3000") ? ("http://localhost:5000/") : ("https://api.kotadigivice.com/")
		
		Axios({
			url: apiUrl + "testimonies",
			method: "get"
		})
		.then(response => {
			this.setState({
				testimonies: response.data
			})
			console.log(this.state.testimonies)
		})
		.catch(err => {
			console.log(err)
		})
	}

	componentDidMount() {
		this.getTestimony()
	}
}
