import React, { Component } from 'react';
import './ProductsFlyer.sass'
import Laptop from "../../../img-component/laptop2.png"
import MainButton from "../../MainButton/MainButton"

export default class ProductsFlyer extends Component {
    state = {
        productName: "Products",
        img: Laptop,
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor.",
        description: '“From Cloud platform to technical and business expertise - we’ve got you covered!” ',
        // brief_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. "
        brief_desc: "We build platforms and offer you a wide range of products and services just as what your company needs to help you achieve your company goals and objectives! From Billing and Invoicing, Payroll and HR, Inventory and Procurement, Finance and Insurance, Asset Management, Healthcare, and Education. Our team will create software products and operational systems for enterprise, government, dental clinic, education."
    }

    render() {
        return (
            <div className="product">
                <div className="sometext">
                    <p className="title">{this.state.productName}</p>
                    <p className="subtitle">{this.state.description}</p>
                    <img className="product-image" src={this.state.img} alt="laptop"/>
                    <p className="product-brief">{this.state.brief_desc}</p>
                </div>
                <div className="buttons">
                    <MainButton mr={3} href="/register" text="Try it for FREE" />
                    <MainButton href="/try" text="Explore Product" />
                </div>
            </div>
        )
    }
}
