import React, { Component } from 'react'
import './CustomerFlyer.sass'
import inesco from "../../../customer-icon/1inesco.png"
import ojk from "../../../customer-icon/2ojk.png"
import bimaaero from "../../../customer-icon/3bimaaero.png"
import sophiamooi from "../../../customer-icon/4sophiamooi.png"
import ciwaru from "../../../customer-icon/5ciwaru.png"
import vbs from "../../../customer-icon/6vbs.png"
import solusiconsulting from "../../../customer-icon/7solusiconsulting.png"
import rsservice from "../../../customer-icon/8rsservice.png"
import astra from "../../../customer-icon/9astra.png"
import wakaf from "../../../customer-icon/10wakaf.png"
import ipnetwork from "../../../customer-icon/11ipnetwork.png"
import djki from "../../../customer-icon/12djki.png"
import iseec from "../../../customer-icon/13iseec.png"
import mizushima from "../../../customer-icon/14mizushima.png"
import muaman from "../../../customer-icon/15muaman.png"

export default class CustomerFlyer extends Component {
    state = {
        productName: "Customer",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor.",
        customers: [
            {
                id: 1,
                customerLogo: inesco
            },
            {
                id: 2,
                customerLogo: ojk
            },
            {
                id: 3,
                customerLogo: bimaaero
            },
            {
                id: 4,
                customerLogo: sophiamooi
            },
            {
                id: 5,
                customerLogo: ciwaru
            },
            {
                id: 6,
                customerLogo: vbs
            },
            {
                id: 7,
                customerLogo: solusiconsulting
            },
            {
                id: 8,
                customerLogo: rsservice
            },
            {
                id: 9,
                customerLogo: astra
            },
            {
                id: 10,
                customerLogo: wakaf
            },
            {
                id: 11,
                customerLogo: ipnetwork
            },
            {
                id: 12,
                customerLogo: djki
            },
            {
                id: 13,
                customerLogo: iseec
            },
            {
                id: 14,
                customerLogo: mizushima
            },
            {
                id: 15,
                customerLogo: muaman
            },
        ]
    }

    render() {
        var customerLogo = this.state.customers.map(customer => {
            return (
                <div className="logo-wrapper">
                    <img id={"icon-" + customer.id} src={customer.customerLogo} alt="customer-logo" key={customer.id}/>
                </div>
            )
        })

        return (
            <div className="customers">
                <p className="title">{this.state.productName}</p>
                <p className="subtitle">{this.state.description}</p>
                <div className="customer-icons">
                    { customerLogo }
                </div>
            </div>

        )
    }
}
