import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import './App.sass'
import Container from 'react-bootstrap/Container'
import HomePage from './Pages/HomePage/HomePage'
import ProductPage from './Pages/ProductPage/ProductPage'
import ServicePage from './Pages/ServicePage/ServicePage'
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage'
import ArticlePage from './Pages/ArticlePage/ArticlePage'
import RegisterPage from './Pages/RegisterPage/RegisterPage'
import ContactUsPage from './Pages/ContactUsPage/ContactUsPage'
import TryPage from './Pages/TryPage/TryPage'
import NotFoundPage from './Pages/ErrorPage/NotFoundPage'
import ErrorPage from './Pages/ErrorPage/ErrorPage'
// import TestimoniCarousel from './Component/TestimoniCarousel/TestimoniCarousel'

function App() {
  return (
    <Container fluid className="App">
      <Router>
        <Switch>
          <Route path="/products/:id_product" component = {ProductPage} />
          <Route path="/article/:id_article" component = {ArticlePage} />
          <Route path="/services/:id_service" component = {ServicePage} />
          <Route path="/error/:status_code" component = {ErrorPage} />
          <Route exact path="/register" component = {RegisterPage} />
          <Route exact path="/contact" component = {ContactUsPage} />
          <Route exact path="/about" component = {AboutUsPage} />
          <Route exact path="/try" component = {TryPage} />
          <Route exact path="/" component = {HomePage} />
          <Route component = {NotFoundPage} />
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
