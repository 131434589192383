import React, { Component } from 'react'
import './RegisterPage.sass'
import {Row, Col, Form} from 'react-bootstrap'
import Bg from '../../bg/homepage.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import CountryList from 'country-list'
import Axios from 'axios';

export default class Register extends Component {
    state= {
        header: {
            title: "Let's Get Started"
        },
        benefits: [
            {
                id: 1,
                desc: "Lorem ipsum dolor sit amet, adipiscing elit. "
            },
            {
                id: 2,
                desc: "Lorem ipsum dolor sit amet, adipiscing elit. "
            },
            {
                id: 3,
                desc: "Lorem ipsum dolor sit amet, adipiscing elit. "
            },
        ],
        firstName: null,
        lastName: null,
        country: null,
        phoneNumber: null,
        company: null,
        slug: null,
        position: null,
        numOfEmployee: null,
        email: null,
        password: null,
        confirmPassword: null
    }

    handleInput = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleOptionChange = (e) => {
        this.setState({
            country : e.target[e.target.selectedIndex].id
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            name: this.state.firstName + " " + this.state.lastName,
            country: this.state.country,
            phoneNumber: this.state.phoneNumber,
            position: this.state.position,
            email: this.state.email,
            password: this.state.password,
            confimPassword: this.state.confirmPassword,
            company: this.state.company,
            numOfEmployee: this.state.numOfEmployee,
            slug: this.state.slug
        }

        const dataJson = JSON.stringify(data)

        Axios({
            url: "http://hudson-poutine-96943.herokuapp.com/api/v1/moon-register/",
            method: "post",
            data: dataJson
        }).then(response => {
            if (response.data.code === "Success") {
                window.location.href = response.data.messages
            } else {
                window.location.href = "/error/" + 500
            }
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }
    render() {
        var benefits = this.state.benefits.map(benefit => {
            return (
                <div className="benefit">
                    <FontAwesomeIcon className="circle" icon={faCircle} />
                    <p className="benefit-desc" key={benefit.id}>{benefit.desc}</p>
                </div>
            )
        })
        var countryOptions = CountryList.getNames().map(country => {
            return(
                <option id={CountryList.getCode(country)}>{country}</option>
            )
        })
        return (
            <Row className="register-page-wrapper">
                <Col className="register-page">
                    <img src={Bg} id="bg" alt="" />
                    <BaseNavbar />
                    <Row className="register-head-wrapper">
                        <Col className="register-head" lg={{ span: 3, offset: 1 }} xs={{ span: 12 }}>
                            <Row>
                                <Col className="header" lg={{ span: 12 }}>
                                    <h1 className="title">{this.state.header.title}</h1>
                                    <h3 className="benefit-label">Your Benefits</h3>
                                    <div className="benefits">
                                        {benefits}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="form-wrapper" lg={{ span: 6, offset: 1 }}>
                            <Form className="form" onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required id="firstName" type="text" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required id="lastName" type="text" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control required as="select" onChange={this.handleOptionChange}>
                                                <option disabled selected>Select country</option>
                                                {countryOptions}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control required id="phoneNumber" 
                                                            placeholder="+62123456789" 
                                                            onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control required id="company" type="text" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Slug</Form.Label>
                                            <Form.Control required id="slug" type="text" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Position</Form.Label>
                                            <Form.Control required id="position" type="text" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>No. of Employee</Form.Label>
                                            <Form.Control required id="numOfEmployee" type="number" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required id="email" type="text" onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control required id="password" type="password"  onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control required id="confirmPassword" 
                                                            type="password" 
                                                            pattern={this.state.password} 
                                                            title="Those passwords did'nt match. Try again."
                                                            placeholder= "Re-type password"
                                                            onChange={this.handleInput} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <button className="submit">Try it now</button>
                            </Form>
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
}
