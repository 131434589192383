import React, { Component } from 'react'
import {Row, Col} from 'react-bootstrap'
import './NotFoundPage.sass'
import Bg from '../../bg/error-bg.jpg'
import BaseNavbar from '../../Component/BaseNavbar/BaseNavbar'
import Footer from '../../Component/Footer/Footer'
import MainButton from '../../Component/MainButton/MainButton'

export default class NotFoundPage extends Component {
    state = {
        header: {
            title: "404",
            desc: "ERROR",
            detail: "Page not Found",
            errorMessage: "Sorry, the page you are looking for could not be found",
            buttonText: "Back to homepage"
        }
    }
    render() {
        return (
            <Row className="not-found-page-wrapper">
                <Col className="not-found-page">
                    <img src={Bg} id="bg" alt="" />
                    <Row className="page-head-wrapper">
                        <Col className="page-head" lg={{ span: 10, offset: 1 }} xs={{ span: 12 }}>
                            <Row>
                                <Col className="header" lg={{ span: 12 }}>
                                    <h1 className="title">{this.state.header.title}</h1>
                                    <p className="desc">{this.state.header.desc}</p>
                                    <p className="desc-detail">{this.state.header.detail}</p>
                                    <p className="error">{this.state.header.errorMessage}</p>
                                    <MainButton href="/" text={this.state.header.buttonText} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Footer />
                </Col>
            </Row>
        )
    }
}

