import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap'
import './LoginModal.sass'
import Logo from "../../../LogoKota/Kota_complogo_light.svg"
import IconGoogle from "../../../img-component/google.png"
import GoogleLogin from 'react-google-login'
import Axios from 'axios';

export default class LoginModal extends Component {
    state= {
        email: null,
        password: null
    }

    handelInput = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
        console.log(this.state)
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let data  = {
            email: this.state.email,
            password: this.state.password
        }
        const dataJson = JSON.stringify(data)

        Axios({
            url: "http://hudson-poutine-96943.herokuapp.com/api/v1/moon-login/",
            method: "post",
            data: dataJson
        }).then(response => {
            if (response.data.code === "Success") {
                window.location.href = response.data.messages
            } else {
                window.location.href = "/error/" + 500
            }
        }).catch(err => {
            window.location.href = "/error/" + 500
        })
    }

    render() {
        const responseGoogle = (response) => {
            console.log(response);
        }

        return (
            <Modal
                {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="custom-modal"
            >
                <Modal.Body>
                    <div className="left">
                        <div className="left-content">
                            <p className="form-title">Sign in with email and password</p>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control id="email" required type="email" onChange={this.handelInput}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control id="password" required type="password" onChange={this.handelInput} />
                                </Form.Group>
                                <Form.Group controlId="endform" className="endform">
                                    <Form.Check type="checkbox" label="Remember me" />
                                    <div className="forgot-password">
                                        <a href="#">Forgot Password?</a>
                                    </div>
                                </Form.Group>
                                <button type="submit" className="submit">Login</button>
                            </Form>
                            <p className="google-label">Sign In with Google</p>
                            <GoogleLogin
                                clientId="411576225017-upk6uba5sn6c8p6iaheidetrd37kg2f9.apps.googleusercontent.com"
                                buttonText="Sign in with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={renderProps => (
                                    <button className="google-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                        <span className="icon">
                                            <img src={IconGoogle} alt="google-icon"/>
                                        </span> 
                                        Sign in with Google
                                    </button>
                                )}
                            />
                        </div>
                    </div>
                    <div className="right">
                        <div className="right-content">
                            <img src={Logo} alt="logo"/>
                            <p className="heading">New starts now</p>
                            <p className="sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut nulla tortor. Fusce ornare consequat tortor element</p>
                            <a href="/register" className="regis-button">Register</a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}